import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import CartContext from "../context/CartContext";

import { Link } from "gatsby";
import { BsFillPersonFill } from "react-icons/bs";
import CardPillTagSection from "./card-pill-tag-section";
import DistanceTo from "./distance-to";
import HeartForCard from "./heart-for-card";
import ImageSlider from "./image-slider";
import TubeDistance from "./tube-distance";
import PopupForm from "./popup-form";
import { Button, Modal } from "react-bootstrap";
import ExpertCard from "./expert-card";
import ExpertCardPopup from "./expert-card-popup";
import { MdOutlineClear } from "react-icons/md";
import PopupFormViewing from "./popup-form-viewing";

const slugify = require("slugify");
function round5(x) {
	return Math.ceil(x / 5) * 5;
}

const NoLinkPropertyCard = ({
	property,
	index,
	//   features,
	imageHeight,
}) => {
	const {
		cart,
		onLocationChangeScroll,
		getDistanceFromLatLonInKm,
		OnAddToCart,
		OnRemoveFromCart,
		distancePoint,
		numberOfPeople,
		budgetPerDesk,
		selectedLocation,
	} = React.useContext(CartContext);

	// const refTb = useRef();
	// const tbShow = useOnScreen(refTb, "0px");
	// useEffect(() => {
	// 	onLocationChangeScroll(
	// 		property.node.locationLatitude,
	// 		property.node.locationLongitude
	// 	);
	// }, [tbShow]); //THIS IS RUN THE FIRST TIME THE SITE IS OPENED

	const locationLatitude = property?.node?.locationLatitude;
	const locationLongitude = property?.node?.locationLongitude;

	const [vidShow, setVidShow] = useState(false);
	const [formShow, setFormShow] = useState(false);

	const handleVidShow = () => setVidShow(true);
	const handleVidClose = () => setVidShow(false);

	const handleFormShow = () => setFormShow(true);
	const handleFormClose = () => setFormShow(false);

	const photos = property?.node?.photos;
	const name = property?.node?.name;
	const subwayStation1 = property?.node?.subwayStation1;
	const subwayStation1DistanceKm = property?.node?.subwayStation1DistanceKm;
	const subwayStation1DurationMins = property?.node?.subwayStation1DurationMins;
	const subwayStation2 = property?.node?.subwayStation2;
	const subwayStation2DistanceKm = property?.node?.subwayStation2DistanceKm;
	const subwayStation2DurationMins = property?.node?.subwayStation2DurationMins;
	const rentPM = property?.node?.rentPM;
	const desksFrom = property?.node?.desksFrom;
	const airtableId = property?.node?.airtableId;
	const type = property?.node?.type;
	const features = property?.node?.features;
	const desksTo = property?.node?.desksTo;
	const floorsAvailable = property?.node?.floorsAvailable;

	var floors = [];
	if (floorsAvailable !== null) {
		floors =
			floorsAvailable
				?.sort((a, b) => parseFloat(a.rentPM) - parseFloat(b.rentPM))
				.slice() || [];
	}
	const lowerCapacity = Math.max(desksFrom, numberOfPeople?.min);

	return (
		<div
			className="h-100 pb-4 position-relative"
			onMouseEnter={() => {
				onLocationChangeScroll(locationLatitude, locationLongitude);
			}}
			style={{
				borderRadius: "10px",
				boxShadow: "0px 1px 20px #00000029",
				overflow: "hidden",
			}}
		>
			<Modal
				style={{ zIndex: 9999999, backgroundColor: "rgba(0, 0, 0, 0.2)" }}
				size="xl"
				show={vidShow}
				centered
				onHide={handleVidClose}
				className=" w-100 p-0 rounded "
			>
				<div className="position-relative">
					<div
						className="px-3 pt-2 position-absolute end-0 top-0"
						style={{ zIndex: 3 }}
					>
						<MdOutlineClear
							style={{ cursor: "pointer" }}
							className="fs-4"
							onClick={handleVidClose}
						/>
					</div>
					<Modal.Body
						style={{ overflow: "hidden" }}
						className="p-0 w-100 rounded "
						closeButton
					>
						<div className="py-3 px-3 px-md-4 px-lg-5 mb-2 bg-white ">
							<Row>
								<Col>
									<h3 className="pb-0 ">
										Book a viewing of{" "}
										<span className="text-dark-blue fw-bold">
											{property?.node.name}
										</span>
									</h3>
								</Col>
							</Row>
							<Row>
								<Col>
									<p>
										Explore our instantly available office spaces at your
										convenience and schedule a flexible viewing today to find
										your ideal workspace.
									</p>
								</Col>
							</Row>
							<PopupFormViewing
								formPropertyName={property?.node.name}
								subject="Book a viewing"
							/>
						</div>
						<ExpertCardPopup />
					</Modal.Body>
				</div>
			</Modal>
			<Modal
				className=" w-100 p-0 rounded "
				style={{ zIndex: 99999, backgroundColor: "rgba(0, 0, 0, 0.2)" }}
				size="xl"
				show={formShow}
				onHide={handleFormClose}
				centered
			>
				<div className="position-relative">
					<div
						className="px-3 pt-2 position-absolute end-0 top-0"
						style={{ zIndex: 3 }}
					>
						<MdOutlineClear
							style={{ cursor: "pointer" }}
							className="fs-4"
							onClick={handleFormClose}
						/>
					</div>

					<Modal.Body
						style={{ overflow: "hidden" }}
						className="p-0 w-100 rounded "
						closeButton
					>
						<div className="py-3 px-3 px-md-4 px-lg-5 mb-2 bg-white ">
							<Row>
								<Col>
									<h3 className="pb-0 ">
										Get a quote for{" "}
										<span className="text-dark-blue fw-bold">
											{property?.node.name}
										</span>
									</h3>
								</Col>
							</Row>
							<Row>
								<Col>
									<p>
										Contact us today to receive a personalized quote and find
										your ideal workspace.
									</p>
								</Col>
							</Row>
							<PopupForm
								formPropertyName={property?.node.name}
								subject="Get a quote"
							/>
						</div>
						<ExpertCardPopup />
					</Modal.Body>
				</div>
			</Modal>{" "}
			<div className="text-decoration-none text-black">
				<div
					className="d-flex position-absolute pt-3 fs-6"
					style={{ zIndex: 1 }}
				>
					<div
						className="btn btn-white rounded-pill px-4 py-1 mx-4"
						style={{ fontSize: "80%" }}
					>
						{type}
					</div>
				</div>
				<Row className="mb-2" style={{ borderBottom: "1px solid #f5f5f5" }}>
					<Col>
						<div style={{ height: `${imageHeight ? imageHeight : "250px"}` }}>
							<ImageSlider
								height={imageHeight ? imageHeight : "250px"}
								imageDataArr={photos}
							/>
						</div>
					</Col>
				</Row>
			</div>
			<Row className="px-3">
				<Col xs={12} style={{ minHeight: "100%" }}>
					<div className="  position-relative  w-100">
						<div className="text-decoration-none w-90 text-black">
							<DistanceTo
								selectedLocation={selectedLocation}
								getDistanceFromLatLonInKm={getDistanceFromLatLonInKm}
								distancePoint={distancePoint}
								locationLatitude={locationLatitude}
								locationLongitude={locationLongitude}
							/>
						</div>
						<div
							style={{ zIndex: 4 }}
							className="position-absolute end-0 top-0"
						>
							<HeartForCard
								cart={cart}
								airtableId={airtableId}
								name={name}
								OnRemoveFromCart={OnRemoveFromCart}
								property={property}
								OnAddToCart={OnAddToCart}
							/>
						</div>
					</div>
				</Col>
			</Row>
			<div className="text-decoration-none text-black">
				<Row className="px-3">
					<Col xs={12}>
						<div className="text-decoration-none text-black">
							<h3 className="fs-3 pb-3">{name}</h3>
						</div>
					</Col>
				</Row>
				{slugify(property?.node.city).toLowerCase() === "london" && (
					<div>
						<Row className="px-3">
							<Col xs={12}>
								{subwayStation1 && (
									<TubeDistance
										station={subwayStation1}
										distanceKm={subwayStation1DistanceKm}
										walkTime={subwayStation1DurationMins}
									/>
								)}
							</Col>
						</Row>
						{subwayStation2 && (
							<Row className="px-3 pb-3">
								<Col xs={12}>
									<TubeDistance
										station={subwayStation2}
										distanceKm={subwayStation2DistanceKm}
										walkTime={subwayStation2DurationMins}
									/>
								</Col>
							</Row>
						)}
					</div>
				)}
				<Row className="px-3">
					<Col>
						<div
							style={{ fontSize: "90%" }}
							className=" align-items-center pb-3 mb-2"
						>
							<BsFillPersonFill className="me-2" />
							<p className="d-inline-block pb-0 mb-0">
								Up to {desksTo} desks available
							</p>
						</div>
					</Col>
				</Row>
				<Row className="px-4">
					<hr className="text-med-grey" />
				</Row>
				<Row className="px-4">
					<Col xs={12}>
						<blockquote>
							{type === "Serviced Office" && budgetPerDesk?.perDesk && (
								<>
									<p className="mb-0">Private offices from</p>
									<h5 className="mt-1 fs-4">
										£
										{`${
											rentPM === null || rentPM === undefined
												? "POA"
												: `${round5(rentPM).toLocaleString("en-UK")}pcm`
										}`}
									</h5>
								</>
							)}
							{type === "Serviced Office" && !budgetPerDesk?.perDesk && (
								<>
									<p className="mb-0">Private offices from</p>
									<h5 className="mt-1 fs-4">
										£
										{`${
											rentPM === null || rentPM === undefined
												? "POA"
												: `${round5(rentPM * lowerCapacity).toLocaleString(
														"en-UK"
												  )}pcm`
										}`}
									</h5>
								</>
							)}
							{(property?.node.type === "Managed Office" ||
								property?.node.type === "Flex Lease") && (
								<>
									<p className="mb-0">Private floors from</p>
									<h5 className="mt-1 fs-4">
										£
										{`${
											floors[0]?.rentPM === null ||
											floors[0]?.rentPM === undefined
												? "POA"
												: `${round5(floors[0]?.rentPM).toLocaleString(
														"en-UK"
												  )}pcm`
										}`}
									</h5>
								</>
							)}
						</blockquote>
					</Col>
				</Row>
				<Row className="px-4">
					<CardPillTagSection features={features} />
				</Row>
			</div>
			<Row className="d-md-none">
				<Col className="px-4 mt-3  d-md-flex justify-content-between">
					<Button
						onClick={handleFormShow}
						className={`text-white btn btn-primary  me-md-3  w-100 w-md-50 fw-bold `}
					>
						Get a quote
					</Button>
					<Button
						onClick={handleVidShow}
						className={`text-white mt-3 mt-md-0 btn btn-dark-blue   w-100 w-md-50 fw-bold `}
					>
						Book a viewing
					</Button>
				</Col>
			</Row>
			<div style={{ height: "38px" }} className="w-100 d-none d-md-block"></div>
			<div className="position-absolute d-none  d-md-block pb-4 w-100 start-0 bottom-0">
				<Row>
					<Col className="px-4 mt-3  d-md-flex justify-content-between">
						<Button
							onClick={handleFormShow}
							className={`text-white btn btn-primary  me-md-3  w-100 w-md-50 fw-bold `}
						>
							Get a quote
						</Button>
						<Button
							onClick={handleVidShow}
							className={`text-white mt-3 mt-md-0 btn btn-dark-blue   w-100 w-md-50 fw-bold `}
						>
							Book a viewing
						</Button>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default NoLinkPropertyCard;
